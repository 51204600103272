import React from 'react';
import { StreamConfig } from '../Config';

export interface StreamViewParams extends StreamConfig {

}

function StreamView(params: StreamViewParams) {
    const channel = params.channel;
    const parent = "twitchbot.eluxo.net"
    const muted = "true";
    const src = `https://player.twitch.tv/?channel=${channel}&parent=${parent}&muted=${muted}`;
    console.log(src);
    return (
        <div className="stream-view">
          <iframe src={src} className="ratio ratio-16x9 stream-view"></iframe>
        </div>
    );
}

export default StreamView;
