import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';


function SidebarView() {
    interface BtnEntry {
        page: string;
        title: string;
        active: boolean;
        icon: string;
    };
    
    const navigate = useNavigate();
    const location = useLocation();

    const buttons: BtnEntry[] = [{
        page: "/obs",
        title: "Obs",
        active: location.pathname === "/obs",
        icon: "bi bi-broadcast"
    }, {
        page: "/sounds",
        title: "Sounds",
        active: location.pathname === "/sounds",
        icon: "bi bi-soundwave"
    }, {
        page: "/scene",
        title: "Scene",
        active: location.pathname === "/scene",
        icon: "bi bi-layout-wtf"
    }, {
        page: "/light",
        title: "Light",
        active: location.pathname === "/light",
        icon: "bi bi-lightbulb"
    }, {
        page: "/shoutout",
        title: "Shoutout",
        active: location.pathname === "/shoutout",
        icon: "bi bi-megaphone"
    }, {
        page: "/stream",
        title: "Stream",
        active: location.pathname === "/stream",
        icon: "bi bi-person-video"
    }];

    return (
      <nav id="sidebar" className='h-100' >{
        buttons.map((entry, index) => {
          return (
            <Row key={index} >
                <Button
                        className={"btn " + (entry.active ? "btn-primary" : "btn-secondary") }
                        onClick={() => navigate(entry.page)} >
                        <div className="menu-button-label">
                          <i className={entry.icon}></i>
                          {entry.title}
                        </div>
                </Button>
            </Row>
          );
        })
      }</nav>
    );
}

export default SidebarView;
