import React from 'react';
import Button from 'react-bootstrap/Button';

export interface PageButtonsParams {
    page: number;
    count: number;
    setPage: (page: number) => void;
}

export function PageButtons(params: PageButtonsParams) {
    function increase() {
        const page = params.page + 1;
        if (page >= params.count) return;
        params.setPage(page);
    }

    function decrease() {
        const page = params.page - 1;
        if (page < 0) return;
        params.setPage(page);
    }

    function hasNext() {
        return params.page < (params.count - 1);
    }

    function hasPrev() {
        return params.page > 0;
    }

    return (
        <div className="d-flex justify-content-around bd-highlight">
            <Button className={hasPrev() ? "" : "invisible"}
                    variant="outline-light"
                    size="sm"
                    onClick={() => decrease()}>Prev</Button>
            <div>Page {params.page + 1} of {params.count}</div>
            <Button className={hasNext() ? "" : "invisible"}
                    variant="outline-light"
                    size="sm"
                    onClick={() => increase()}>Next</Button>
        </div>
    )
}

export default PageButtons;
