import React, { useEffect, useState } from 'react';
import { socket } from '../socket';

export function useSocketConnected() {
    const [socketConnected, setSocketConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
            setSocketConnected(true);
        }

        function onDisconnect() {
            setSocketConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, []);
    return socketConnected;
}
