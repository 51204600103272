import React from 'react';
import { useSocketConnected } from '../hooks/useSocketConnected';
import online from '../assets/online.svg';
import offline from '../assets/offline.svg';

export function OnlineStatusView() {
    const connected = useSocketConnected();

    const css = "w-100 online-status text-end p-1 pe-3 m-0 mt-1 text-uppercase fw-bold " +
        (connected ? "online-status-good" : "online-status-bad");
    return <div className={css} >
        {connected ? "online" : "offline"}
        <img className="ps-2" src={connected ? online : offline} />
    </div>
}
