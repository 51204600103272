import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HashRouter, Routes, Route, NavLink } from "react-router-dom";
import SidebarView from './components/SidebarView';
import StreamView from './components/StreamView';
import ButtonMatrixView from './components/ButtonMatrixView';
import Config from './Config';
import { useSocketConnected } from './hooks/useSocketConnected';
import { socket } from './socket';
import { OnlineStatusView } from './components/OnlineStatusView';

function App() {
  const connected = useSocketConnected();
  
  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    }
  }, []);

  return (
    <div className="App">
      <HashRouter>
        <Row className="w-100 h-100" >
          <Col className="col-buttons" sm={10} >
            <Routes>
              <Route path="/obs" index element={<ButtonMatrixView {...Config.obs} page={0} />} />
              <Route path="/sounds" element={<ButtonMatrixView {...Config.sounds} page={0} />} />
              <Route path="/scene" element={<ButtonMatrixView {...Config.scene} page={0} />} />
              <Route path="/light" element={<ButtonMatrixView  {...Config.light} page={0} />} />
              <Route path="/shoutout" element={<div>Shoutout</div>} />
              <Route path="/stream" element={<StreamView  {...Config.stream} />} />
            </Routes>
          </Col>
          <Col className="col-menu m-0 p-0" sm={2} >
            <OnlineStatusView />
            <SidebarView />
          </Col>
        </Row>
      </HashRouter>
    </div>
  );
}

export default App;
