export interface ButtonEntryType {
    title: string;
    icon: string | null;
    color: string;
    module: string;
    action: string;
    params: any | null;
    callback: (module: string, params: any | null) => void;
};

export type ButtonEntry = ButtonEntryType | null;

export interface ButtonConfig {
    buttons: ButtonEntry[];
};

export interface ShoutoutConfig {

};

export interface StreamConfig {
    channel: string;
};

export interface GlobalConfig {
    channel: string;
}

export interface Config {
    global: GlobalConfig;
    obs: ButtonConfig;
    sounds: ButtonConfig;
    scene: ButtonConfig;
    light: ButtonConfig;
    shoutout: ShoutoutConfig;
    stream: StreamConfig;
};

function make_buttons(count: number): ButtonEntry[] {
    const rc: ButtonEntry[] = [];

    function dummy(module: string, params: any | null) {
        console.log("button clicked", module, params)
    }

    for (let i = 0; i < count; i++) {
        rc.push({
            callback: dummy,
            action: `dummy button ${i}`,
            module: "dummy",
            color: "btn-secondary",
            icon: "bi-asterisk",
            title: `Button ${i}`,
            params: null
        })
    }
    return rc;
}

export const Values: Config = {
    global: {
        channel: "testluxo"
    },

    obs: {
        buttons: make_buttons(7)
    },

    sounds: {
        buttons: make_buttons(13)
    },

    scene: {
        buttons: make_buttons(23)
    },

    light: {
        buttons: make_buttons(42)
    },

    shoutout: {

    },

    stream: {
        channel: "testluxo",
    }
};

export default Values;
