import React, { useEffect, useState } from 'react';
import { ButtonConfig, ButtonEntry } from '../Config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageButtons from './PageButtons';
import { Button } from 'react-bootstrap';


export interface ButtonMatrixParams extends ButtonConfig {
    page: number;
}

function ButtonMatrixView(params: ButtonMatrixParams) {
    const [page, setPage] = useState<number>(params.page + 0);
    const [count, setCount] = useState<number>(5);

    const rowsPerPage = 6;
    const colsPerRow = 6;

    useEffect(() => {
        const pageCount = Math.ceil(params.buttons.length / (rowsPerPage * colsPerRow));
        console.log('use effect', params.page, pageCount);
        setPage(params.page);
        setCount(pageCount);
        console.log(page, count);
        if (page >= pageCount) {
            console.log("setPage", pageCount);
            setPage(pageCount - 1);
        }
    }, [params]);

    function renderCol(button: ButtonEntry, index: number) {
        const className = 'w-100 h-100 p-0 m-0 btn ' + button?.color
        let view = <Button className={className + ' invisible'}></Button>
        const icon = (button?.icon != null) ? 'bi ' + button.icon : null;
        if (button) {
            view = <Button className={className} onClick={
                    () => button.callback(button.module, button.action)
                }>
                { icon && <i className={icon} ></i> } {button.title}
            </Button>
        }

        return <Col key={index} className='w-100 d-100 p-1 m-0'>{ view }</Col>
    }

    function renderRow(row: number) {
        const buttons: ButtonEntry[] = [];
        for (let i = row * colsPerRow; i < (row + 1) * colsPerRow; ++i) {
            if (i >= params.buttons.length) {
                buttons.push(null);
            } else {
                buttons.push(params.buttons[i]);
            }
        }

        return <Row>
            { buttons.map(renderCol) }
        </Row>
    }

    function renderRows(page: number) {
        const rows: number[] = [];
        for (let i = page * rowsPerPage; i < (page + 1) * rowsPerPage; ++i) rows.push(i);
        return <>{
            rows.map(value => {
                return <Row className='p-0 m-0' style={{height: '16%'}}>{ renderRow(value) }</Row>
            })
        }</>
    }

    return (
        <div className='h-100 w-100 d-flex align-items-end flex-column'>
            <div className='button-matrix w-100 h-100' >
                { renderRows(page) }
            </div>
            <div className='mt-auto w-100 page-buttons'><PageButtons page={page} count={count} setPage={setPage} /></div>
        </div>
    );
}

export default ButtonMatrixView;
